import { ConfigProvider, Tabs, Col, Image, Row, Tooltip } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import P0Navbar from "../Home/P0Navbar";
import P6Footers from "../Home/P6Footers";
import PSN1Header from "./PSN1Header";
import PSN2Divison from "./PSN2Divison";
import PSN3Saraban from "./PSN3Saraban";
import PSN4Meeting from "./PSN4Meeting";
import PSN5Coor from "./PSN5Coor";
import PSNLoading from "./PSNLoading";

const apiURLNews = `${process.env.REACT_APP_API_URL}/noauth/clients/personnel/data`;

const Personnels = ({}) => {
  const [loading, setloading] = useState(false);
  const [dataPersonnel, setDataPersonnel] = useState([]);

  const fetchDataPersonnel = async () => {
    setloading(true);
    await axios.get(apiURLNews).then((res) => {
      setDataPersonnel(res.data);
    });
    setloading(false);
  };

  const [hoveredItemId, setHoveredItemId] = useState(null);

  const handleMouseEnter = (itemId) => {
    setHoveredItemId(itemId);
  };

  const handleMouseLeave = () => {
    setHoveredItemId(null);
  };

  useEffect(() => {
    fetchDataPersonnel();
  }, []);

  const [activeTab, setActiveTab] = useState("1");

  const onChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div className="container-xxl bg-white p-0">
      <div className="container-xxl position-relative p-0">
        <P0Navbar />
        <PSN1Header />
        <div className="container px-lg-5 mb-5">
          {loading ? (
            <PSNLoading />
          ) : (
            <>
              <div
                className="section-title position-relative text-center mb-5 pb-5 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <h2 className="mt-5">ผู้อำนวยการกองบริหารงานกลาง</h2>
              </div>

              <div className="row pb-5">
                {dataPersonnel ? (
                  /* eslint-disable-next-line */
                  dataPersonnel.map((item, index) => {
                    return (
                      <Row>
                        <Col
                          span={24}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {item.ranking === "ผู้อำนวยการ" ? (
                            <div
                              className="col-lg-4 col-md-12 wow fadeInUp"
                              data-wow-delay="0.1s"
                            >
                              <div className="team-item">
                                <div className="d-flex">
                                  <div
                                    className="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5"
                                    style={{ width: "75px" }}
                                  >
                                    <Tooltip title="คัดลอกโทรศัพท์">
                                      <a
                                        className="btn btn-square text-primary bg-white my-1"
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            item.phones.substring(2)
                                          );
                                        }}
                                      >
                                        <i className="bi bi-telephone-fill"></i>
                                      </a>
                                    </Tooltip>
                                    <Tooltip
                                      placement="left"
                                      title="คัดลอกอีเมล์"
                                    >
                                      <a
                                        className="btn btn-square text-primary bg-white my-1"
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            item.emails
                                          );
                                        }}
                                      >
                                        <i className="bi bi-envelope-fill"></i>
                                      </a>
                                    </Tooltip>
                                    <Tooltip
                                      placement="bottom"
                                      title="เข้าสู่ Microsoft Teams"
                                    >
                                      <a
                                        className="btn btn-square text-primary bg-white my-1"
                                        href="https://cmu.to/FLh2l"
                                        target="_blank"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-microsoft-teams"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M9.186 4.797a2.42 2.42 0 1 0-2.86-2.448h1.178c.929 0 1.682.753 1.682 1.682v.766Zm-4.295 7.738h2.613c.929 0 1.682-.753 1.682-1.682V5.58h2.783a.7.7 0 0 1 .682.716v4.294a4.197 4.197 0 0 1-4.093 4.293c-1.618-.04-3-.99-3.667-2.35Zm10.737-9.372a1.674 1.674 0 1 1-3.349 0 1.674 1.674 0 0 1 3.349 0Zm-2.238 9.488c-.04 0-.08 0-.12-.002a5.19 5.19 0 0 0 .381-2.07V6.306a1.692 1.692 0 0 0-.15-.725h1.792c.39 0 .707.317.707.707v3.765a2.598 2.598 0 0 1-2.598 2.598h-.013Z" />
                                          <path d="M.682 3.349h6.822c.377 0 .682.305.682.682v6.822a.682.682 0 0 1-.682.682H.682A.682.682 0 0 1 0 10.853V4.03c0-.377.305-.682.682-.682Zm5.206 2.596v-.72h-3.59v.72h1.357V9.66h.87V5.945h1.363Z" />
                                        </svg>
                                      </a>
                                    </Tooltip>
                                  </div>
                                  {item.imageIDPOne === "" ||
                                  item.imageIDPTwo === "" ? (
                                    <div
                                      className="img-personnel rounded"
                                      style={{
                                        width: "80%",
                                        height: "380px",
                                        overflow: "hidden",
                                        transition: "0.7s",
                                        position: "relative",
                                        borderRadius: "10px",
                                        display: "block",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        background:
                                          "linear-gradient(140deg, #ffffff 72%, #f7f7f7 38%)",
                                      }}
                                    >
                                      <Image
                                        preview={false}
                                        style={{
                                          width: "100%",
                                        }}
                                        src={`${process.env.PUBLIC_URL}/Assets/img/logo-cropped.png`}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      onMouseEnter={() =>
                                        handleMouseEnter(item._id)
                                      }
                                      onMouseLeave={handleMouseLeave}
                                      className="img-personnel rounded"
                                      style={{
                                        width: "80%",
                                        height: "380px",
                                        overflow: "hidden",
                                        transition: "0.7s",
                                        position: "relative",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <Image
                                        preview={false}
                                        style={{ width: "100%" }}
                                        src={
                                          hoveredItemId === item._id
                                            ? item.imageIDPTwo
                                            : item.imageIDPOne
                                        }
                                        alt={`Person ${index + 1}`}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="px-4 py-3">
                                  <h5 className="fw-bold m-0">
                                    {item.prefix + item.fname}&nbsp;{item.lname}
                                  </h5>
                                  <small>{item.ranking}</small>
                                  <br />
                                  <small>
                                    โทร : {item.phones.substring(2)}
                                  </small>
                                  <br />
                                  <small>E-Mail : {item.emails}</small>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <>ไม่พบข้อมูล</>
                )}
              </div>
            </>
          )}
        </div>
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                cardGutter: "50",
                itemSelectedColor: "#9171ee",
                itemHoverColor: "#fba504",
                cardBg: "null",
              },
            },
          }}
        >
          <Tabs
            type="card"
            size="large"
            animated={true}
            centered={true}
            tabPosition={"top"}
            defaultActiveKey={activeTab}
            onChange={onChange}
            items={[
              {
                key: "1",
                label: "ศูนย์บริหารกลาง (CSC)",
                children: <PSN2Divison />,
              },
              {
                key: "2",
                label: "สารบรรณและธุรการ",
                children: <PSN3Saraban />,
              },
              {
                key: "3",
                label: "ประชุมและพิธีการ",
                children: <PSN4Meeting />,
              },
              {
                key: "4",
                label: "ศูนย์ประสานงานมหาวิทยาลัย (กทม.)",
                children: <PSN5Coor />,
              },
            ]}
          />
        </ConfigProvider>
      </div>
      <P6Footers />
    </div>
  );
};
export default Personnels;
