import { Dropdown } from "antd";

const itemOnes = [
  {
    label: (
      <a rel="noopener noreferrer" href="/saraban">
        งานสารบรรณและธุรการ
      </a>
    ),
  },
  {
    label: (
      <a rel="noopener noreferrer" href="/meeting">
        งานประชุมและพิธีการ
      </a>
    ),
  },
  {
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://cmucoor.oop.cmu.ac.th"
      >
        งานศูนย์ประสานงานมหาวิทยาลัย (กทม.)
      </a>
    ),
  },
];

const itemsTwos = [
  {
    key: "1",
    label: (
      <a rel="noopener noreferrer" href="/orgchart">
        โครงสร้างหน่วยงาน
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a rel="noopener noreferrer" href="/personnel">
        บุคลากร
      </a>
    ),
  },
];

const generateKey = (prefix, index) => `${prefix}-${index}`;

const itemThreeKey = () => {
  const itemThrees = [
    {
      label: "งานสารบรรณและธุรการ",
      children: [
        // {
        //   label: (
        //     <a
        //       target="_blank"
        //       rel="noopener noreferrer"
        //       href={`${process.env.PUBLIC_URL}/Assets/PDF/รหัสพยัญชนะ_เลขที่หนังสือ.pdf`}
        //     >
        //       รหัสพยัญชนะและเลขที่หนังสือ
        //     </a>
        //   ),
        // },

        {
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://hr.oop.cmu.ac.th/van_reservations"
            >
              บริการรถตู้ปรับอากาศ
            </a>
          ),
        },

        {
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://documentcadoop.oou.cmu.ac.th/login"
            >
              ทะเบียนหนังสือส่ง
            </a>
          ),
        },

        {
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docnumber.mis.cmu.ac.th/"
            >
              ออกเลขที่หนังสือ
            </a>
          ),
        },

        {
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.sys.oop.cmu.ac.th/meetingroom/signups/login"
            >
              จองห้องประชุม
            </a>
          ),
        },

        {
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://emeeting.cmu.ac.th/"
            >
              E-Meeting
            </a>
          ),
        },

        {
          label: (
            <a
              rel="noopener noreferrer"
              href="/saraban/docu"
            >
              เอกสารดาวน์โหลด
            </a>
          ),
        },
      ],
    },

    {
      type: "divider",
    },

    {
      label: "งานประชุมและพิธีการ",
      children: [
        {
          label: (
            <a href="/meeting/board">
              รายชื่อคณะกรรมการบริหารมหาวิทยาลัยเชียงใหม่
            </a>
          ),
        },

        {
          label: <a href="/meeting/report">สืบค้นรายงานการประชุมย้อนหลัง</a>,
        },

        {
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.PUBLIC_URL}/Assets/PDF/กำหนดการประชุม_กบม_67.pdf`}
            >
              กำหนดการประชุม กบม.
            </a>
          ),
        },
      ],
    },

    {
      type: "divider",
    },

    {
      label: (
        <a
          style={{ color: "#000" }}
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_LOGIN_BLACKEND}`}
        >
          เข้าสู่ระบบ
        </a>
      ),
    },
  ];

  return itemThrees.map((itemThree, itemIndex) => {
    if (itemThree.children) {
      return {
        key: generateKey("group", itemIndex + 1),
        type: "group",
        label: itemThree.label,
        children: itemThree.children.map((child, childIndex) => ({
          key: generateKey(`group-${itemIndex + 1}`, childIndex + 1),
          label: child.label,
        })),
      };
    } else {
      return {
        key: itemIndex + 1,
        type: itemThree.type,
        label: itemThree.label,
      };
    }
  });
};

const itemsThree = itemThreeKey();

const P0Navbar = ({ setbackground = "", setopacity = "" }) => {
  const fetchItemsOne = itemOnes.map((itemOne, index) => {
    return { ...itemOne, key: index.toString() };
  });

  const fetchItemsTwo = itemsTwos.map((itemsTwo, index) => {
    return { ...itemsTwo, key: index.toString() };
  });

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0"
        style={{ backgroundColor: setbackground, opacity: setopacity }}
      >
        <a href="/" className="navbar-brand p-0">
          <h1 className="m-0">
            <img
              className="me-2"
              data-wow-delay="0.5s"
              src={`/Assets/img/logo-cropped.png`}
            />
          </h1>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="fa fa-bars"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0">
            <a href="/" className="nav-item nav-link active">
              หน้าแรก
            </a>

            <Dropdown
              menu={{
                items: fetchItemsOne,
              }}
            >
              <div className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  onClick={(e) => e.preventDefault()}
                >
                  หน่วยงานภายใน
                </a>
              </div>
            </Dropdown>

            <Dropdown
              menu={{
                items: fetchItemsTwo,
              }}
            >
              <div className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  onClick={(e) => e.preventDefault()}
                >
                  เกี่ยวกับเรา
                </a>
              </div>
            </Dropdown>

            <Dropdown
              menu={{
                items: itemsThree,
              }}
            >
              <div className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  onClick={(e) => e.preventDefault()}
                >
                  บริการ
                </a>
              </div>
            </Dropdown>
          </div>
        </div>
      </nav>
    </>
  );
};
export default P0Navbar;
