import { Button, Col, Row, Table } from "antd";
import P0Navbar from "../Home/P0Navbar";
import P6Footers from "../Home/P6Footers";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";

const PSB3Header = () => {
  return (
    <>
      <div className="container-xxl py-5 bg-primary hero-header-orgchart mb-5">
        <div
          style={{
            // backgroundColor: "hsl(262, 67%, 48%, 0.8)",
            height: "40px",
          }}
        ></div>
        <div className="container-xxl py-5 mb-5 news-images-title" />
      </div>

      <div className="container px-lg-5">
        <div className="row g-5 py-4">
          <div className="col-12 text-center news-bg-title">
            <h1 className="text-white animated zoomIn news-text-title">
              เอกสารดาวน์โหลด
            </h1>
            <hr className="bg-white mx-auto mt-0" style={{ width: "50%" }} />
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center news-time-title">
                ดาวน์โหลดเอกสารต่าง ๆ ของกองบริหารงานกลางสำนักงานมหาวิทยาลัย
                มหาวิทยาลัยเชียงใหม่
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

const columns = [
  {
    title: "ลำดับ",
    dataIndex: "key",
    width: "5%",
  },

  {
    title: "ชื่อเอกสาร",
    dataIndex: "name",
    key: "name",
    width: "40%",
  },

  {
    title: "หมวดหมู่",
    dataIndex: "category",
    showSorterTooltip: {
      target: "full-header",
    },
    filters: [
      {
        text: "สารบรรณ",
        value: "สารบรรณ",
      },
      {
        text: "การเงิน",
        value: "การเงิน",
      },
    ],
    onFilter: (value, record) => record.category.indexOf(value) === 0,
    width: "10%",
  },

  {
    title: "รายละเอียด",
    dataIndex: "detail",
    width: "35%",
  },

  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Row
        gutter={[10, 5]}
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        {record.hidened === true ? (
          <Col>
            <a href={`${record.urldetail}`} target="_blank">
              <Button
                type="primary"
                style={{ backgroundColor: "#9171ee" }}
                icon={<EyeOutlined />}
              >
                ดูตัวอย่าง
              </Button>
            </a>
          </Col>
        ) : (
          <></>
        )}
        <Col>
          <a href={`${record.url}`} target="_blank">
            <Button
              type="primary"
              style={{ backgroundColor: "#389e0d" }}
              icon={<DownloadOutlined />}
            >
              ดาวน์โหลด
            </Button>
          </a>
        </Col>
      </Row>
    ),
    width: "10%",
  },
];
const data = [
  {
    key: "1",
    name: "รหัสพยัญชนะและเลขที่หนังสือ",
    detail:
      "ทะเบียนรหัสพยัญชนะและเลขที่หนังสือของส่วนงานในมหาวิทยาลัยเชียงใหม่",
    url: `${process.env.PUBLIC_URL}/Assets/PDF/รหัสพยัญชนะ_เลขที่หนังสือ.pdf`,
    category: "สารบรรณ",
  },
  {
    key: "2",
    name: "สัญญาการยืมหมุนเวียนกองบริหารงานกลาง",
    detail: "",
    url: `${process.env.PUBLIC_URL}/Assets/PDF/สัญญาการยืมหมุนเวียนกองบริหารงานกลาง.doc`,
    urldetail: `${process.env.PUBLIC_URL}/Assets/PDF/ตัวอย่างสัญญาเงินยืมกองบริหารงานกลาง.pdf`,
    hidened: true,
    category: "การเงิน",
  },
  {
    key: "3",
    name: "ใบเบิกค่าใช้จ่ายในการเดินทางไปปฏิบัติงา",
    detail: "",
    url: `${process.env.PUBLIC_URL}/Assets/PDF/ใบเบิกค่าใช้จ่ายในการเดินทางไปปฏิบัติงา.doc`,
    category: "การเงิน",
  },
  {
    key: "4",
    name: "ตารางหลักฐานการจ่ายเงินค่าใช้จ่าย",
    detail: "",
    url: `${process.env.PUBLIC_URL}/Assets/PDF/ตารางหลักฐานการจ่ายเงินค่าใช้จ่าย.xlsx`,
    category: "การเงิน",
  },
];

const PSB3Docu = () => {
  return (
    <div className="container-xxl bg-white p-0">
      <div className="container-xxl position-relative p-0">
        <P0Navbar />
        <PSB3Header />
        <div className="container-xxl py-5">
          <div className="container px-lg-5">
            <Table
              style={{ border: "1px solid #f0f0f0", borderRadius: "5px" }}
              columns={columns}
              dataSource={data}
              pagination={false}
              showHeader={true}
              align={"center"}
            />
          </div>
        </div>
        <P6Footers />
      </div>
    </div>
  );
};

export default PSB3Docu;
